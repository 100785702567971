<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-lg-12 mb-0">
                <div class="card">
                    <div class="card-body">
                        <div class="float-sm-left">
                            <router-link to="/patients" class="btn btn-info"
                                ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 mx-0">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar-xl mx-auto mt-1">
                            <div class="avatar-title bg-light rounded-circle">
                                <i class="mdi mdi-account h1 m-0 text-body"></i>
                            </div>
                        </div>

                        <h4 class="mt-3 mb-0">{{ patient.identity.surname }} {{ patient.identity.othernames }}</h4>
                        <p class="text-muted">{{ patient.identity.email }}</p>

                        <button type="button" class="btn btn-danger btn-xs waves-effect mb-2 waves-light">
                            {{ patient.identity.accountType }}
                        </button>

                        <div class="text-left mt-3">
                            <h4 class="font-13 text-uppercase">Address :</h4>
                            <p class="text-muted font-13 mb-3">
                                {{ patient.identity.address }}
                            </p>

                            <div class="table-responsive">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Gender :</th>
                                            <td class="text-muted">{{ patient.identity.gender }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Mobile :</th>
                                            <td class="text-muted">{{ patient.identity.phoneNumber }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Date of birth :</th>
                                            <td class="text-muted">{{ patient.identity.dob | moment('MMMM Do YYYY') }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Place of Work :</th>
                                            <td class="text-muted">{{ patient.identity.placeOfWork }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- end card-box -->
                </div>
            </div>
            <!-- end col-->

            <div class="col-lg-8 col-xl-8 mx-0">
                <div class="card">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title> <i class="mr-1"></i>Prescriptions </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-hover table-centered mb-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Prescription Name</th>
                                                    <th>Qty of tablet</th>
                                                    <th>Dosage duration</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="p in prescription" :key="p.id">
                                                    <td>{{ p.id }}</td>
                                                    <td>App design and development</td>
                                                    <td>{{ p.quantityOfTablets }}</td>
                                                    <td>{{ p.dosageDuration }}</td>
                                                    <td>
                                                        <span class="badge p-1" :class="p.status === 'PENDING' ? 'badge-danger' : 'badge-primary'">{{
                                                            p.status
                                                        }}</span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                            <b-tab>
                                <template v-slot:title> <i class="mdi mdi-settings-outline mr-1"></i>Doctors </template>
                                <form>
                                    <h5 class="mb-3 text-uppercase bg-light p-2"><i class="mdi mdi-account-circle mr-1"></i> Doctor Info</h5>
                                    <!-- end row -->
                                </form>
                            </b-tab>
                            <b-tab>
                                <template v-slot:title> <i class="mdi mdi-settings-outline mr-1"></i>Orders </template>
                                <div class="table-responsive mb-0">
                                    <table class="table table-hover table-centered mb-0">
                                        <thead class="thead-light">
                                            <tr>
                                                <!--                                            <th>#</th>-->
                                                <th>Original price</th>
                                                <th>Amount sold at</th>
                                                <th>Discounted price</th>
                                                <th>Qty</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="o in orders" :key="o.id">
                                                <!--                                            <td>{{o.orderNumber}}</td>-->
                                                <td>
                                                    <span class="badge badge-outline-secondary p-1">{{ o.originalPrice }} </span>
                                                </td>
                                                <td>
                                                    <span class="badge badge-outline-primary p-1">{{ o.price }} </span>
                                                </td>
                                                <td>
                                                    <span class="badge badge-outline-danger p-1">{{ o.discountedPrice }} </span>
                                                </td>
                                                <td>
                                                    <span class="text-secondary font-weight-semibold">{{ o.quantity }} </span>
                                                </td>
                                                <td>
                                                    <span
                                                        class="badge p-1"
                                                        :class="o.status === 'PENDING_CONFIRMATION' ? 'badge-danger' : 'badge-primary'"
                                                        >{{ o.status }}</span
                                                    >
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>
            </div>
            <!-- end col -->
        </div>
    </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/Page-header';
import BaseUrl from '../../../components/constants';

export default {
    name: 'ViewPatient',
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            patient: {},
            prescription: [],
            orders: [],
            title: 'Patient Detail',
            items: [
                {
                    text: '',
                },
                {
                    text: 'Dashboards',
                },
                {
                    text: 'patient',
                    active: true,
                },
            ],
        };
    },
    methods: {
        getPatientDetailsById() {
            BaseUrl.AxiosBearer.get(`/admin/patients/profile/${this.$route.params.Id}`)
                .then((resp) => {
                    if (resp.data.status) {
                        this.patient = resp.data.payload;
                        this.getPatientsPrescriptionsByPaitenId();
                        this.getPatientsOrdersById();
                    }
                })
                .catch((error) => {
                    console.log('error : ', error.message);
                });
        },
        getPatientsPrescriptionsByPaitenId() {
            BaseUrl.AxiosBearer.get(`/admin/patients/profile/${this.$route.params.Id}/prescriptions?page=1&pageSize=100000`)
                .then((resp) => {
                    if (resp.data.status) {
                        this.prescription = [];
                        this.prescription = resp.data.payload;
                    }
                })
                .catch((error) => {
                    console.log('error : ', error.message);
                });
        },

        getPatientsOrdersById() {
            BaseUrl.AxiosBearer.get(`/admin/patients/profile/${this.$route.params.Id}/orders?page=1&pageSize=100000`)
                .then((resp) => {
                    if (resp.data.status) {
                        this.orders = [];
                        this.orders = resp.data.payload;
                    }
                })
                .catch((error) => {
                    console.log('error : ', error.message);
                });
        },
    },
    created() {
        this.getPatientDetailsById();
    },
};
</script>

<style scoped></style>
